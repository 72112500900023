@tailwind base;
@tailwind components;
@tailwind utilities;

.DOEItem:hover
{
    .plusIcon
    {
        display: block !important;
    }
}